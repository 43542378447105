/* CalendarWithCheckboxes.css */
.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

/* .react-calendar {
    width: 100%;
    max-width: 1100px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
} */

.react-calendar__navigation button {
    color: #6f48eb;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
    font-weight: bold;
    color: #6f48eb;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 10px 6.6667px;
    background: none;
    flex-basis: 14.2857%;
    height: 100px;
}

.react-calendar__tile--now {
    background: #ffff76;
    border-radius: 6px;
}

.react-calendar__tile--active {
    background: #6f48eb;
    color: white;
    border-radius: 6px;
}

.festiu {
    background-color: red;
    color: white;
}

.checked-day {
    background-color: green;
    color: white;
}

.checkboxes {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
