/* CalendarWithCheckboxes.css */
.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Altura completa de la ventana */
}

.react-calendar {
  width: 100%; /* Ancho del calendario */
  /* max-width: 1000px; Ancho máximo del calendario */
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.react-calendar__navigation button {
    color: #678685;
    min-width: 44px;
    background: none;
    font-size: 3vh;
    margin-top: 8px;
}

.react-calendar__month-view__days__day {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.react-calendar__tile--now {
  background: #F5F0BB;
  color: #64748b;
}

.react-calendar__tile--now:hover {
  background: #ebe6b3 !important;
  color: #64748b;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #aca778;
}

.react-calendar__tile--active {
    background: green !important;
    color: #fff;
}

.checkboxes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.checkboxes input[type="checkbox"] {
    width: 25px;
    height: 25px;
    margin: 1vh;
    accent-color: green;
}


.festiu {
  background-color: #FF8080; /* o cualquier estilo que desees aplicar a los días festivos */
  color: white;
}

.checked-day {
  background-color: #79AC78;
  color: white;
}


.checkboxes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}
/* .react-calendar_month-viewdays_day {
  margin: 12px !important;
} */

abbr {
    font-family: Montserrat;
    font-size: larger;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}