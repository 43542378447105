@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body,
#root {
  height: 100%;
}

.sidebar:before {
  content: '';
  position: absolute;
  width: 220px;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: white;
  border: none;
}

.Toastify__toast--success {
  background-color: #fff !important;
}

.Toastify__toast--error {
  background-color: #f08b6c !important;
}

/* 
----------------------------------------------------------
  Default page print style:
----------------------------------------------------------
*/
@media print {
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
  }

  @page {
    size: A4 portrait;
    margin-right: 0cm;
    margin-bottom: 0.85cm;
    margin-left: 0cm;
    margin-top: 0.85cm;
    padding: 0cm;
  }
}
/* 
----------------------------------------------------------
  Default page print style.
----------------------------------------------------------
*/

.flex.flex-row.col-span-1.relative {
  margin-bottom: 15px;
}

.lg\:text-2xl {
  font-size: 1.3rem !important;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

/* .grid.grid-cols-12.pr-2.text-center.bg-white.border-t-2.border-indigo-800.print\:hidden {
    display: none;
} */

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: -webkit-fill-available;
}

button.text-indigo-800.hover\:text-blue-700 {
    width: 100%;
    display: ruby;
    height: 55px;
}

input[type=checkbox] {
  accent-color: #4f46e5;
}
